<template>
    <FooterUnlogged v-if="!loggedIn" />
    <FooterLogged v-else />
</template>

<script setup lang="ts">
    import {storeToRefs} from 'pinia';
    import {FooterLogged, FooterUnlogged} from '#components';
    import {useAuth} from '#imports';

    const {loggedIn} = storeToRefs(useAuth());
</script>
