<template>
    <ClientOnly>
        <footer class="legal-footer">
            © {{ currentYear }} Malt ⋅
            <a :href="legalLink" target="_blank" rel="noreferrer nopener">{{ t('navbar.footer.cgu.cgv') }}</a>
            ⋅
            <a :href="protectionLink" target="_blank" rel="noreferrer nopener">{{ t('navbar.footer.data.protection') }}</a>
        </footer>
    </ClientOnly>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {useTranslation} from '#imports';

    const {t} = useTranslation();

    const currentYear = new Date().getFullYear();

    const legalLink = computed(() => `/legal`);
    const protectionLink = computed(() => `/about/privacy`);
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .legal-footer {
        width: calc(100% - var(--layout-padding-left));
        position: relative;
        padding: var(--joy-core-spacing-4) 10%;
        color: var(--joy-color-neutral-40);
        letter-spacing: -0.2px;
        font-size: var(--joy-font-size-primary-300);
        font-family: var(--joy-font-family);

        @include mq.screen_sm {
            padding: 0 5%;
        }

        a {
            color: var(--joy-color-neutral-40);
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
</style>
